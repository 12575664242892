.familiafade {
  animation: fadein 1.5s;
  -moz-animation: fadein 1.5s; /* Firefox */
  -webkit-animation: fadein 1.5s; /* Safari and Chrome */
  -o-animation: fadein 1.5s; /* Opera */
  margin-bottom: 2rem;
}
@keyframes fadein {
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-moz-keyframes fadein { /* Firefox */
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-o-keyframes fadein { /* Opera */
  from {
      opacity:0;
  }
  to {
      opacity: 1;
  }
}

@media (max-width: 750px) {
  .logoNCnone {
    display: none;
    border-style:none;
  }
}

@media (max-width: 750px) {
  .borderNone {
    border-style:none !important;
  }
}

@media (min-width: 700px) {
  .logoNCtrue {
    display: none;
    
  }
}