.titulo{
    padding: 2rem;
    font-family: 'Arial narrow'
}

.fuente{
    font-family: 'Arial narrow'
}

.cardStyle{
    padding: 1rem;
}