.Badges__hero {
  width: 100%;
  padding: 1rem;
  
  background-repeat: repeat;
  margin-bottom: 1rem;
}

.Badges__hero>.Badges__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Badges__container {
  width: 100%;
  max-width: 580px;
  margin: 0 auto;
  padding: 0 1rem;
}

.Badges_conf-logo {
  margin-bottom: 2rem;
}

.Badges__buttons {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}

.btn-coronavirus{
  background-color:red;
  padding:0.1rem;
  text-align: center;
} 

.textInfoCoronavirus{
  color: white;
}

.pos{
 margin-bottom: 0.5rem;
 margin-top: 0.5rem;
}

.radioMunicipal{
  width: 100px;
}
.rwCapital{
  font: 150% sans-serif;
  margin-top: 1rem;
  color:#4DB1AB;
  margin-top: 2rem;
}

.borde{
  border-bottom-style: solid;
  border-bottom-width:1px;
  border-color: #4DB1AB;
}

.bannerDist{
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
}

.cardsDist{
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.posAyudanos{
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.footer{
  background-color: #4DB1AB;
}

.carouselFonts{
  color: #4DB1AB;
}

.prueba{
  margin-bottom: 4rem;
}

.BtncardInfo{
  background:rgb(63, 214, 204)  !important; 
  color: black;
}

.anchoContainer{
  max-width: 95%;
}

.imgSyle{
  /*width: 90%;*/
  max-height: 80%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.cardPadding{
  padding: 0.5rem;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.fondo {
  background-color: crimson;
}

.puente {
  background: url("../../images/puente.jpg");
  background-position: 50% 94px;
  width: 100%;
  display: block;
  position: relative;
  overflow: visible;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #fff;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  padding: 100px 0; 
}

.tonina {
  background: url("../../images/tonina.jpg");
  background-position: 50% 94px;
  width: 100%;
  display: block;
  position: relative;
  overflow: visible;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #fff;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  padding: 100px 0;
}

.palacio {
  background: url("../../images/palacio.jpg");
  background-position: 50% 94px;
  width: 100%;
  display: block;
  position: relative;
  overflow: visible;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #fff;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  padding: 60px 0;
  
}



.rowAltura{
  height: 15rem;
}

.noticiaSec{
  width: 10px;
}

.titularNombres{
  font-size: calc(0.6em + 0.3vw);
  color:black;
  text-align: center;
}

/*------------------------Guia Tramites--------------------------*/

.marS{
  margin-top: 3rem;
}
.marS2{
  margin-top: 7rem;
}
.vert{
  vertical-align: middle;
}

/*----------------------guia tramites-------------------------------*/

.cardPadding{
  padding-bottom: 0.5rem;
}

/*----------------------rentas Tramites----------------------------*/

.noLink{
  text-decoration: none !important;
  color: black !important;
 
}

.backHeader{
  background-color: #33FF96;
}

.headerTramites{
  background-color: white;
  border-bottom: 1px solid;
  
}

.cardAlto{
  height: 100%;
}


@media (min-width: 850px) {
  
  .radio-img {-webkit-transform:scale(1.0);transform:scale(0.5);}
 
}

@media (min-width: 1200px) {
 
  .radio-img{-webkit-transform:scale(1.0);transform:scale(1.0);}
  .radio-img{
    margin-top: 1rem;
    margin-left: 0.1rem;
  }
  
}

@media (min-width: 1300px) {
  
  .containerCustom{
    margin-left: auto;
    margin-right: auto;
    width: 1280px;
    border-right: solid;
    border-left: solid;
    border-width: 0.3em;
    border-color:rgba(107,226,219,1);
  }
  
  .radio-img{-webkit-transform:scale(1.0);transform:scale(1.08);}
  .radio-img{
    margin-top: 3rem;
    margin-left: 2.1rem;
  }
  
}

@media (min-width: 1600px) {
  
  .containerCustom{
    margin-left: auto;
    margin-right: auto;
    width: 1400px;
    border-right: solid;
    border-left: solid;
    border-width: 0.3em;
    border-color:rgba(107,226,219,1);
  }
  
  .radio-img{-webkit-transform:scale(1.0);transform:scale(1.08);}
  .radio-img{
    margin-top: 3rem;
    margin-left: 2.1rem;
  }
  
}

@media (min-width: 1920px) {
  
  .containerCustom{
    margin-left: auto;
    margin-right: auto;
    width: 1700px;
    border-right: solid;
    border-left: solid;
    border-width: 0.3em;
    border-color:rgba(107,226,219,1);
  }
  
  .radio-img{-webkit-transform:scale(1.0);transform:scale(1.08);}
  .radio-img{
    margin-top: 3rem;
    margin-left: 2.1rem;
  }
  
}

.img-tramite:hover {
  -webkit-transform:scale(1.09);
  transform:scale(1.09);
  transition-duration:0.5s
}
.img-tramite {overflow:hidden;}
.img-tramite:hover {filter: contrast(70%);}

@media (min-width: 50em){
  .wrapper {
    display: grid;
    grid-template-columns: 45% 55%;
    grid-gap: 10px;
    color: #444;
    margin-left: 1rem;
    margin-right: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .box {
    background-color:#4DB1AB;
    color: #fff;
    border-radius: 5px;
    padding: 1rem;
    font-size: 100%;
  }
  
  .d {/*carrusel*/
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 3;
  }
  
  .c {/*video*/
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
  }
  .f {/*banner*/
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
  }
  }

/*
.cabecera{
  height: 70%;
  display: flex;
  padding: 0.5rem;
}

.cabecera1{
  height: 50%;
  width: 43%;
  padding: 0.25rem; 
}

.cabecera2{
  height: 50%;
  width: 57%;
  padding: 0.25rem;
}

.cabecera2_a{
  width: 100%;  
}

.cabecera2_b{
  width: 100%;
  margin-top: 0.5rem;
}
*/

@media (max-width: 47em){
  .cabecera{
    flex-direction: column;
  }
  .cabecera1{
    width: 100%;
  }
  .cabecera2{
    width: 100%;
  }
  .cabecera2_a{
    width: 100%;  
  }
  .cabecera2_b{
    width: 100%;
    margin-top: 0.1rem;
  }
}



.fadeHome {
  animation: fadein 1.3s;
  -moz-animation: fadein 1.3s; /* Firefox */
  -webkit-animation: fadein 1.3s; /* Safari and Chrome */
  -o-animation: fadein 1.3s; /* Opera */
}

.fadeHomeInicio {
  animation: fadein 0.8s;
  -moz-animation: fadein 0.8s; /* Firefox */
  -webkit-animation: fadein 0.8s; /* Safari and Chrome */
  -o-animation: fadein 0.8s; /* Opera */
}

@keyframes fadein {
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-moz-keyframes fadein { /* Firefox */
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-o-keyframes fadein { /* Opera */
  from {
      opacity:0;
  }
  to {
      opacity: 1;
  }
}


