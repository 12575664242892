.Badge {
  background: #FFFFFF;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.10);
  border-radius: 8px 8px 8px 8px;
  overflow: hidden;
  height: 380px;
}

.Badge__header {
  padding: 0.5rem 0;
  height: 80px;
  background: #1B1B25;
  display: flex;
  justify-content: center;
}

.Badge__section-name {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
}

.Badge__section-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0;
  background: #F4F4F7;
}


.titularCarousel{
  font-size: calc(0.65em + 0.6vw);
  margin-top: -47%;
  color:white;
}


.rawsonNavBAr {
  background: rgb(107,226,219) !important;
  background: linear-gradient(114deg, rgba(107,226,219,1) 0%, rgba(9,133,125,1) 100%) !important;
}
.rawsonLinks{
  margin-left: 3rem !important;
}
.linkNoneDec{
  color: black !important;
  text-decoration: none !important;
}

#opcionDropDown:active {
  background: rgb(9,133,125,1) !important;
}
.rawsonFooterA {
    background: rgb(107,226,219);
    background: linear-gradient(114deg, rgba(107,226,219,1) 0%, rgba(9,133,125,1) 100%);
}
.rawsonFooter {
    background: rgb(107,226,219);
    background: linear-gradient(114deg, rgba(107,226,219,1) 0%, rgba(9,133,125,1) 100%);
    margin-top: 0rem;
}
.escudo{
    margin-right: 3rem;
    padding: 1rem;
}

.modernizacion{
    float: right;
}
.contacto{
    margin-top: 1rem;
    text-align-last: center ;
}
.text-Links{
    color: rgb(189, 225, 236) !important;
}

.Badges__hero {
  width: 100%;
  padding: 1rem;
  
  background-repeat: repeat;
  margin-bottom: 1rem;
}

.Badges__hero>.Badges__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Badges__container {
  width: 100%;
  max-width: 580px;
  margin: 0 auto;
  padding: 0 1rem;
}

.Badges_conf-logo {
  margin-bottom: 2rem;
}

.Badges__buttons {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}

.btn-coronavirus{
  background-color:red;
  padding:0.1rem;
  text-align: center;
} 

.textInfoCoronavirus{
  color: white;
}

.pos{
 margin-bottom: 0.5rem;
 margin-top: 0.5rem;
}

.radioMunicipal{
  width: 100px;
}
.rwCapital{
  font: 150% sans-serif;
  margin-top: 1rem;
  color:#4DB1AB;
  margin-top: 2rem;
}

.borde{
  border-bottom-style: solid;
  border-bottom-width:1px;
  border-color: #4DB1AB;
}

.bannerDist{
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
}

.cardsDist{
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.posAyudanos{
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.footer{
  background-color: #4DB1AB;
}

.carouselFonts{
  color: #4DB1AB;
}

.prueba{
  margin-bottom: 4rem;
}

.BtncardInfo{
  background:rgb(63, 214, 204)  !important; 
  color: black;
}

.anchoContainer{
  max-width: 95%;
}

.imgSyle{
  /*width: 90%;*/
  max-height: 80%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.cardPadding{
  padding: 0.5rem;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.fondo {
  background-color: crimson;
}

.puente {
  background: url(/static/media/puente.e554cfe1.jpg);
  background-position: 50% 94px;
  width: 100%;
  display: block;
  position: relative;
  overflow: visible;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #fff;
  background-size: cover;
  box-sizing: border-box;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  padding: 100px 0; 
}

.tonina {
  background: url(/static/media/tonina.234e1fed.jpg);
  background-position: 50% 94px;
  width: 100%;
  display: block;
  position: relative;
  overflow: visible;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #fff;
  background-size: cover;
  box-sizing: border-box;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  padding: 100px 0;
}

.palacio {
  background: url(/static/media/palacio.4711e0a5.jpg);
  background-position: 50% 94px;
  width: 100%;
  display: block;
  position: relative;
  overflow: visible;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #fff;
  background-size: cover;
  box-sizing: border-box;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  padding: 60px 0;
  
}



.rowAltura{
  height: 15rem;
}

.noticiaSec{
  width: 10px;
}

.titularNombres{
  font-size: calc(0.6em + 0.3vw);
  color:black;
  text-align: center;
}

/*------------------------Guia Tramites--------------------------*/

.marS{
  margin-top: 3rem;
}
.marS2{
  margin-top: 7rem;
}
.vert{
  vertical-align: middle;
}

/*----------------------guia tramites-------------------------------*/

.cardPadding{
  padding-bottom: 0.5rem;
}

/*----------------------rentas Tramites----------------------------*/

.noLink{
  text-decoration: none !important;
  color: black !important;
 
}

.backHeader{
  background-color: #33FF96;
}

.headerTramites{
  background-color: white;
  border-bottom: 1px solid;
  
}

.cardAlto{
  height: 100%;
}


@media (min-width: 850px) {
  
  .radio-img {-webkit-transform:scale(1.0);transform:scale(0.5);}
 
}

@media (min-width: 1200px) {
 
  .radio-img{-webkit-transform:scale(1.0);transform:scale(1.0);}
  .radio-img{
    margin-top: 1rem;
    margin-left: 0.1rem;
  }
  
}

@media (min-width: 1300px) {
  
  .containerCustom{
    margin-left: auto;
    margin-right: auto;
    width: 1280px;
    border-right: solid;
    border-left: solid;
    border-width: 0.3em;
    border-color:rgba(107,226,219,1);
  }
  
  .radio-img{-webkit-transform:scale(1.0);transform:scale(1.08);}
  .radio-img{
    margin-top: 3rem;
    margin-left: 2.1rem;
  }
  
}

@media (min-width: 1600px) {
  
  .containerCustom{
    margin-left: auto;
    margin-right: auto;
    width: 1400px;
    border-right: solid;
    border-left: solid;
    border-width: 0.3em;
    border-color:rgba(107,226,219,1);
  }
  
  .radio-img{-webkit-transform:scale(1.0);transform:scale(1.08);}
  .radio-img{
    margin-top: 3rem;
    margin-left: 2.1rem;
  }
  
}

@media (min-width: 1920px) {
  
  .containerCustom{
    margin-left: auto;
    margin-right: auto;
    width: 1700px;
    border-right: solid;
    border-left: solid;
    border-width: 0.3em;
    border-color:rgba(107,226,219,1);
  }
  
  .radio-img{-webkit-transform:scale(1.0);transform:scale(1.08);}
  .radio-img{
    margin-top: 3rem;
    margin-left: 2.1rem;
  }
  
}

.img-tramite:hover {
  -webkit-transform:scale(1.09);
  transform:scale(1.09);
  transition-duration:0.5s
}
.img-tramite {overflow:hidden;}
.img-tramite:hover {-webkit-filter: contrast(70%);filter: contrast(70%);}

@media (min-width: 50em){
  .wrapper {
    display: grid;
    grid-template-columns: 45% 55%;
    grid-gap: 10px;
    color: #444;
    margin-left: 1rem;
    margin-right: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .box {
    background-color:#4DB1AB;
    color: #fff;
    border-radius: 5px;
    padding: 1rem;
    font-size: 100%;
  }
  
  .d {/*carrusel*/
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 3;
  }
  
  .c {/*video*/
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
  }
  .f {/*banner*/
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
  }
  }

/*
.cabecera{
  height: 70%;
  display: flex;
  padding: 0.5rem;
}

.cabecera1{
  height: 50%;
  width: 43%;
  padding: 0.25rem; 
}

.cabecera2{
  height: 50%;
  width: 57%;
  padding: 0.25rem;
}

.cabecera2_a{
  width: 100%;  
}

.cabecera2_b{
  width: 100%;
  margin-top: 0.5rem;
}
*/

@media (max-width: 47em){
  .cabecera{
    flex-direction: column;
  }
  .cabecera1{
    width: 100%;
  }
  .cabecera2{
    width: 100%;
  }
  .cabecera2_a{
    width: 100%;  
  }
  .cabecera2_b{
    width: 100%;
    margin-top: 0.1rem;
  }
}



.fadeHome {
  animation: fadein 1.3s;
  -moz-animation: fadein 1.3s; /* Firefox */
  -webkit-animation: fadein 1.3s; /* Safari and Chrome */
  -o-animation: fadein 1.3s; /* Opera */
}

.fadeHomeInicio {
  animation: fadein 0.8s;
  -moz-animation: fadein 0.8s; /* Firefox */
  -webkit-animation: fadein 0.8s; /* Safari and Chrome */
  -o-animation: fadein 0.8s; /* Opera */
}

@keyframes fadein {
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}



.styleTextDesarrollo{
    margin-top: 5rem;
    margin-bottom: 5rem;
}

.styleTextTitular{
    margin-top: 5rem;
    margin-bottom: 5rem;
}
.lds-grid {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.lds-grid div {
  position: absolute;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #000;
  -webkit-animation: lds-grid 1.2s linear infinite;
          animation: lds-grid 1.2s linear infinite;
}

.lds-grid div:nth-child(1) {
  top: 6px;
  left: 6px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.lds-grid div:nth-child(2) {
  top: 6px;
  left: 26px;
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}

.lds-grid div:nth-child(3) {
  top: 6px;
  left: 45px;
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}

.lds-grid div:nth-child(4) {
  top: 26px;
  left: 6px;
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}

.lds-grid div:nth-child(5) {
  top: 26px;
  left: 26px;
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}

.lds-grid div:nth-child(6) {
  top: 26px;
  left: 45px;
  -webkit-animation-delay: -1.2s;
          animation-delay: -1.2s;
}

.lds-grid div:nth-child(7) {
  top: 45px;
  left: 6px;
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}

.lds-grid div:nth-child(8) {
  top: 45px;
  left: 26px;
  -webkit-animation-delay: -1.2s;
          animation-delay: -1.2s;
}

.lds-grid div:nth-child(9) {
  top: 45px;
  left: 45px;
  -webkit-animation-delay: -1.6s;
          animation-delay: -1.6s;
}

@-webkit-keyframes lds-grid {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }
}

@keyframes lds-grid {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }
}
.test {
  margin-top: 25px;
  font-size: 21px;
  text-align: center;
  animation: fadein 1.5s;
  -moz-animation: fadein 1.5s; /* Firefox */
  -webkit-animation: fadein 1.5s; /* Safari and Chrome */
  -o-animation: fadein 1.5s; /* Opera */
}
@keyframes fadein {
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}

@media (max-width: 750px) {
  .logoNCnone {
    display: none;
    border-style:none;
  }
}

@media (max-width: 750px) {
  .borderNone {
    border-style:none !important;
  }
}

@media (min-width: 700px) {
  .logoNCtrue {
    display: none;
    
  }
}
.familiafade {
  animation: fadein 1.5s;
  -moz-animation: fadein 1.5s; /* Firefox */
  -webkit-animation: fadein 1.5s; /* Safari and Chrome */
  -o-animation: fadein 1.5s; /* Opera */
  margin-bottom: 2rem;
}
@keyframes fadein {
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}

@media (max-width: 750px) {
  .logoNCnone {
    display: none;
    border-style:none;
  }
}

@media (max-width: 750px) {
  .borderNone {
    border-style:none !important;
  }
}

@media (min-width: 700px) {
  .logoNCtrue {
    display: none;
    
  }
}
.PageLoading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
}
.titulo{
    padding: 2rem;
    font-family: 'Arial narrow'
}

.fuente{
    font-family: 'Arial narrow'
}

.cardStyle{
    padding: 1rem;
}
.styleTextDesarrollo{
    margin-top: 5rem;
    margin-bottom: 5rem;
}

.styleTextTitular{
    margin-top: 5rem;
    margin-bottom: 5rem;
}
.bodyCard
{
    text-decoration:none;
   
}

.bodyCard{
    color: black;
}

.bodyCard:hover{
    font-size: large;
    color: crimson;
    font-weight: bold;
}
.styleTextDesarrollo{
    margin-top: 5rem;
    margin-bottom: 5rem;
}

.styleTextTitular{
    margin-top: 5rem;
    margin-bottom: 5rem;
}
