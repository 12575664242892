.bodyCard
{
    text-decoration:none;
   
}

.bodyCard{
    color: black;
}

.bodyCard:hover{
    font-size: large;
    color: crimson;
    font-weight: bold;
}